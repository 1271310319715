import { EditorScriptFlowAPI, FlowEditorSDK, FlowPlatformOptions } from '@wix/yoshi-flow-editor';
import { REVIEWS_APP_ID, THANK_YOU_PAGE_ID, THANK_YOU_WIDGET_ID } from '~/app-ids';
import { addTPAInternalPage, isResponsiveEditor } from '~/editor/page-utils';

export const installTankYouPage = async (
  flowAPI: EditorScriptFlowAPI,
  editorSDK: FlowEditorSDK,
  options: FlowPlatformOptions,
) => {
  const t = flowAPI?.translations.t.bind(flowAPI?.translations);
  const pageRef = await addTPAInternalPage(editorSDK, {
    title: t?.('thank-you-page.title'),
    pageId: THANK_YOU_PAGE_ID,
  });

  const { compId } = await editorSDK.document.tpa.add.component('', {
    componentType: editorSDK.document.tpa.TPAComponentType.Widget,
    appDefinitionId: REVIEWS_APP_ID,
    widget: {
      widgetId: THANK_YOU_WIDGET_ID,
      wixPageId: pageRef.id,
      shouldNavigate: false,
    },
  });
  const componentRef = await editorSDK.document.components.getById('', {
    id: compId,
  });
  await editorSDK.components.layout.update('', {
    componentRef,
    layout: {
      y: 0,
    },
  });
  if (isResponsiveEditor(options)) {
    const responsiveLayout = await editorSDK.document.responsiveLayout.get('', {
      componentRef,
    });
    await editorSDK.responsiveLayout.update('', {
      componentRef,
      responsiveLayout: {
        ...responsiveLayout,
        itemLayouts: [
          {
            ...responsiveLayout.itemLayouts[0],
            justifySelf: 'center',
          },
        ],
      },
    });
  }
};
